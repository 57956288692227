import '../styles/globals.css'
import type { AppProps } from 'next/app'
import Layout from '../components/Layout'
import { Auth0Provider } from '@auth0/auth0-react'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ''}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ''}
      redirectUri={process.env.NEXT_PUBLIC_APP_URL}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </Auth0Provider>
  )
}

export default MyApp
